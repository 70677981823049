<template>
  <VAutocomplete
      :label="label"
      :value="value"
      :search-input.sync="search"
      :items="options"
      :disabled="disabled"
      :readonly="readonly"
      :rules="rules"
      :loading="loading"
      @change="onChange"
      @blur="onBlur"
      outlined
      dense
      autocomplete="chrome-off"
      hideDetails="auto"
  />
</template>

<script>
import { get, map, throttle, isEmpty, find, join, compact } from 'lodash-es';
import { address } from '@/api/dadata';

export default {
  name: 'InputRegionDadata',
  props: {
    label: { type: String },
    value: { type: String },
    disabled: { type: Boolean },
    readonly: { type: Boolean },
    rules: { type: Array, default: () => []},
    locations: { type: Array, default: () => []},
  },
  data() {
    return {
      loading: false,
      search: '',
      suggestions: [],
    }
  },
  computed: {
    options() {
      if (isEmpty(this.suggestions)) {
        if (this.value) return [this.value];
        else return [];
      }
      return map(this.suggestions, (suggestion) => {
        const value = this.formatter(suggestion);
        return {
          value,
          text: value,
          data: suggestion,
        };
      });
    }
  },
  methods: {
    formatter(item) {
      const type = 'region_type';
      const data = get(item, 'data');
      const value = get(item, 'value');
      return join(compact([get(data, type), get(data, 'region')]), '. ') || value;
    },
    onChange: function(value) {
      this.$emit('input', value);
      this.$emit('select', {
        region_iso_code: get(find(this.options, { value }), 'data.data.region_iso_code'),
      });
    },
    onSearch: throttle(function(query) {
      if (query) {
        this.loading = true;
        address(query, {
          restrict_value: true,
          from_bound: { value: 'region' },
          to_bound: { value: 'region' },
          locations: this.locations,
        }).then(({ suggestions }) => {
          this.loading = false;
          this.$set(this, 'suggestions', suggestions);
        })
        .catch(() => this.loading = false);
      }
    }, 1000),
    onBlur: function() {
      this.$emit('input', this.search);
    }
  },
  watch: {
    value: {
      handler: function() {
        this.suggestions = [];
      },
    },
    search: {
      handler: function(query) {
        this.onSearch(query);
      },
    },
  }
}
</script>
